.moduletable{
	&.sketch_dropdown{
		background: $box1;
		margin-bottom: 24px;
		ul.nav{
			margin-left: 0;
			li{
				list-style: none;
				position: relative;
				a{
					display: block;
					padding-left: 6px;
					padding-right: 6px;
					border-bottom: 1px solid $border;
				}
				ul{
					margin-left: 0;
					background: darken($light, 10%);
				}
			}
		}
		span.toggl0r{
			position: absolute;
			right: 6px;
			padding: 3px;
			padding-top: 0;
			cursor: pointer;
			padding-bottom: 0;
			background: $dark;
			color: $light;
			transform: rotate(90deg);
			transition: .3s all ease 0s;
			&:before{
				content: '>';
				font-family: monospace;
			}
			&.active{
				transform: rotate(-90deg);
			}
		}
	}
}