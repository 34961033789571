* {
	font-size: $base-font-size;
	font-family: $base-font-family;
}

body {
	background-color: $light;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	.logo {
		p{
			font-weight: 400;
		}
	}
}

main {
	@include clear();
	padding-top: 62px;
	padding-bottom: 24px;
}
footer{
	width: 100%;
	float: left;
	clear: both;
	background: #e4e4e4;
	padding-top: 24px;
	padding-bottom: 24px;
	.row.flex{
		width: 100%;
	}
	h3{
		color: $red;
		margin-bottom: 24px;
	}
	.moduletable .custom{
		*{
			@include font-size(14);
			color: $base;
		}
		ul{
			list-style: none;
			margin-left: 0;
			margin-bottom: 12px;
			li{
				margin-bottom: 12px;
				color: $base;
			}
		}
		a{
			color: $base;
			&:hover{
				color: $main;
			}
		}
	}
	.content_bottom_1_3, .content_bottom_3_3{
		background: $box1;
		height:100%;
		padding: 12px;
		@media#{$large}{
			padding: 24px;
		}
	}
	.content_bottom_2_3{
		background: $box2;
		height:100%;
		padding: 12px;
		@media#{$large}{
			padding: 24px;
		}
	}
}

.match{
	transition: none !important;
}


body .cc_banner-wrapper:hover {
	a.cc_more_info {

		color: $main !important;

	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}

.top_top {
	position: fixed;
	bottom: 64px;
	@media#{$large}{
		bottom: 0;
	}
	width: 100%;
	box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
	height: 5px;
	background: $main;
	text-align: center;
	z-index: 2;
	&.stick {
		position: relative;
		bottom: 0 !important;
	}
	button.scroll_to_top {
		background: $main;
		display: inline-block;
		height: 45px;
		position: relative;
		width: 45px;
		top: -44px;
		box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
		z-index: 2;
		border-radius: 100% 100% 0 0;
		span.red_arrow {
			background: $light;
			color: $link;
			display: block;
			width: 29px;
			height: 29px;
			border-radius: 100%;
			font-weight: lighter;
			line-height: 35px;
			font-size: 28px;
			margin: 0 auto;
		}
	}
}