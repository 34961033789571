div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media#{$xlarge}{
					margin-left: 24px;
				}
				&:first-child{
					margin-left: 0;
				}
				a {
					@include font-size(12);
				}
			}
		}
	}
}

nav.top_menu {
	.moduletable {
		float: left;
		overflow: visible;
		width: 100%;
		&.slidenav1{
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			li {
				display: inline-block;
				list-style: none;
				position: relative;
				> a, > span {
					padding: 17px 15px;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					@include font-size(16);
					font-weight: normal;
					color: $dark;
					@media#{$xlarge}{
						padding: 17px 24px;
					}
					&.logo {
						padding-bottom: 0;
						padding-top: 0;
						@media#{$xlarge}{
							padding-bottom: 0;
							padding-top: 0;
						}
						background: transparent !important;
						&:before {
							display: none;
						}
						img {
							width: 100px;
							margin-bottom: 8px;
							margin-top: 4px;
						}
					}
					&.icon_facebook{
						@include icon($icon-facebook2);
						font-size: 0;
						border-radius: 100%;
						width: 38px;
						height: 38px;
						padding: 0 !important;
						background: $dark;
						text-align: center;
						&:before{
							@include font-size(30, 1.6);
							text-indent: 0;
							color: $light;
							padding-right: 0;
						}
						&:hover{
							background: #4267B2;
						}
					}
				}
				&:hover > a, &:hover > span{
					color: #000;
				}
				ul.nav-child{
					position: absolute;
					top: 30px;
					left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					overflow: hidden;
					visibility: hidden;
					opacity: 0;
					background: rgba($link,.7);
					z-index: 99;
					min-width: 213px;
					box-shadow: 7px 0px 20px -17px #000;
					li{
						display: block;
						float: none;
						margin-right: 0;
						&:first-child a{
							border-left: 0;
						}
						a{
							@include font-size(16);
							padding: 8px 10px;
							background: $link;
							text-transform: none;
							letter-spacing: 0;
							font-family: $base-font-family;
							font-weight: normal;
							color: $base;
							border-right: 0;
							&:hover{
								background: $light;
							}
							&:after{
								content:'';
							}
							&:first-child{
								border-left: 0;
							}
						}
						&.active{
							a{
								background: $main;
								color: $light;
							}
						}
					}
				}
				&:hover{
					ul.nav-child{
						opacity: 1;
						display: block;
						visibility: visible;
						top: 100%;
					}
				}
				&.active{
					a {
						font-weight: 600;
					}
					ul {
						li{
							a{
							}
						}
					}
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media#{$xlarge}{
					margin-right: 24px;
				}
				&:last-child{
					margin-right: 0;
				}
				a {
					@include font-size(14);
					color: $light;
				}
			}
		}
	}
}
.slidenav1, .slidenav2, .slidenav3, .slidenav4{
	display: none;
	@media #{$large}{
		display: block;
	}
}
/* Icon 4 */

#main-nav {
	width: 60px;
	height: 45px;
	position: relative;
	margin: 32px auto 12px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	float: left;
	display: block;
	@media #{$large}{
		display: none;
	}
	span, &:before, &:after{
		display: block;
		position: absolute;
		height: 9px;
		width: 100%;
		background: $link;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;
	}
	&:before {
		top: 0px;
		content: '';
		transform-origin: left center;
	}
	&:after {
		content:'';
		top: 18px;
		transform-origin: left center;
	}
	span {
		top: 36px;
		transform-origin: left center;
	}
	&.open:before {
		transform: rotate(45deg);
		top: -3px;
		left: 8px;
	}
	&.open:after {
		width: 0;
		opacity: 0;
	}
	&.open span {
		transform: rotate(-45deg);
		top: 39px;
		left: 8px;
	}
	&.open{
		span, &:before, &:after{
			background: $main;
		}
	}
}