.blog, .blog-featured{
	.item{
		@include bgblock();
	}
	.items-leading > div{
		@include bgblock();
		border-bottom: 1px solid $border;
	}
	p.readmore > .btn{
		padding: 2px 10px;
		margin-bottom: 0;
	}
}
.category-list{
	@include bgblock();
}