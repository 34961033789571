.sketchwrap_slider {
	.sketchslider_pro-wrap {
		.sketchslider_pro {
			margin-bottom: 30px;
		}
	}
	.moduletable {
		img {
			display: block;
			float: left;
			width: 100%;
			max-width: 100%;
		}
		.box-text {
			@include clear();
			background: $link;
			padding-top: 24px;
			padding-bottom: 24px;
			font-weight: 700;
			p {
				text-align: center;
				margin-bottom: 0;
				color: $light;
				@include font-size(20);
				@media#{$small}{
					@include font-size(32);
				}
				@media#{$medium}{
				@include font-size(45);
				}
				.blue {
					color: $main;
				}
			}
		}
	}
}

